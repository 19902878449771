import { useEffect, useState } from 'react'
import { useParams, useRouteMatch, Link } from 'react-router-dom'
import {
  Layout, Button, Typography, Row, Card, Tabs, Input, Tag, Modal, Checkbox, Spin, Tooltip,
} from 'antd'
import {
  LeftOutlined, SearchOutlined, EditOutlined, InfoCircleOutlined, DownOutlined, UpOutlined,
  ExclamationCircleOutlined, SaveOutlined, FilePdfOutlined, CopyOutlined, CaretRightOutlined,
} from '@ant-design/icons'

import CompaniesAutocomplete from 'components/autocomplete/Companies'
import usePrevious from 'helpers/usePrevious'
import Api from 'services/api'
import { numberStr } from 'utils/number'
import classNames from 'utils/classNames'
import ImportHubspotCompany from './ImportHubspotCompany'
import Details from './Details'
import NewForm from './form'
import { getInitialsValues } from './form/getInitialsValues'

const { Title } = Typography
const { Content } = Layout

const NewStudyContainer = () => {
  const { feasabilityId } = useParams()
  const { path } = useRouteMatch()
  const [loading, setLoading] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [visibleStudy, setVisibleStudy] = useState(true)
  const [lists, setLists] = useState({})
  const [modal, setModal] = useState(false)
  const [datas, setDatas] = useState([{}, {}])
  const [studyInfos, setStudyInfos] = useState({})
  const [hubspotVisible, setHubspotVisible] = useState(false)
  const [newCompany, setNewCompany] = useState('')
  const [details, setDetails] = useState({})
  const [changeTitle, setChangeTitle] = useState(false)
  const [unsaved, setUnsaved] = useState(false)
  const [activeKey, setActiveKey] = useState('A')
  const [isNotPDF, setIsNotPDF] = useState(false)
  const previousA = usePrevious(datas[0]?.totalProfils > 706325 ? 706325 : datas[0]?.totalProfils)
  const previousB = usePrevious(datas[1]?.totalProfils > 706325 ? 706325 : datas[1]?.totalProfils)

  const params = {
    $populate: [{
      path: '_jobs',
      select: 'reference _company',
      populate: { path: '_company', select: 'name' },
    }],
  }
  useEffect(() => {
    if (feasabilityId) {
      getFeasability()
    }
  }, [feasabilityId])

  useEffect(() => {
    getLists()
    if (!feasabilityId) setUnsaved(true)
  }, [])

  const getFeasability = async () => {
    try {
      setLoading(true)
      const { feasability } = await Api.axios.get(`v4/admin/feasibilities/${feasabilityId}`, { params })
      const { valuesA, valuesB } = getInitialsValues(feasability)
      setStudyInfos({ title: feasability.title, _company: feasability._company })
      setDetails({
        analyse: JSON.parse(feasability.analyse),
        competitor: feasability.competitor,
        recommendations: JSON.parse(feasability.recommendations),
      })
      setDatas([valuesA, valuesB])
    } finally {
      setLoading(false)
    }
  }

  const getPDF = async () => {
    try {
      setLoadingPdf(true)
      const { url } = await Api.axios.get(`v4/admin/feasibilities/${feasabilityId}/pdf`)
      if (url) {
        window.open(url)
      }
    } finally {
      setLoadingPdf(false)
    }
  }

  const handleCreate = async () => {
    try {
      // const datas = [...datas, datas[1].isNotPDF]
      const newDatas = [{ ...datas[0] }, { ...datas[1], isNotPDF }]

      if (feasabilityId) {
        await Api.axios.put(`v4/admin/feasibilities/${feasabilityId}`, {
          type: path.split('/')[1],
          datas: newDatas,
          title: studyInfos.title,
          _company: newCompany || studyInfos._company,
          ...details,
        })
      } else {
        await Api.axios.post('v4/admin/feasibilities', {
          type: path.split('/')[1],
          datas: newDatas,
          title: studyInfos.title,
          _company: newCompany || studyInfos._company,
          ...details,
        })
      }
      setUnsaved(false)
    } finally {
      setModal(false)
    }
  }

  const handleDuplicate = () => {
    setDatas([datas[0], datas[0]])
  }

  const handleDuplicateEDF = async () => {
    try {
      await Api.axios.post(`v4/admin/feasibilities/${feasabilityId}/duplicate`, {
        title: studyInfos.title,
        _company: newCompany || studyInfos._company,
      })
    } finally {
      setModal(false)
    }
  }

  const getLists = async () => {
    const { lists: newLists } = await Api.axios.get('/v4/lists', {
      params: {
        select:
          'functions sectors studyLevels experienceLevels regions departments codesDepartementsRegions languages levelLanguages salesProfiles',
      },
    })
    setLists(newLists)
  }

  return (
    <Layout>
      <div className='flex justify-between items-center border-b px-0'>
        <Row className='flex items-center'>
          <Link to={`/${path.split('/')[1]}/studies`} className="pr-6">
            <LeftOutlined />
          </Link>
          <div className='flex items-center space-x-2'>
            <Title level={4} className='p-0 m-0'> {
              feasabilityId
                ? (
                  <>
                    {changeTitle
                      ? (
                        <div className='flex items-center'>
                          <Input
                            value={studyInfos.title}
                            onChange={(e) => setStudyInfos({ ...studyInfos, title: e.target.value })}
                          />
                        </div>
                      )
                      : (
                        <div className='flex items-center justify-between'>
                          <span>
                            {studyInfos.title}
                          </span>
                          <EditOutlined className='ml-2 w-3 h-3' onClick={() => setChangeTitle(true)} />
                        </div>
                      )}
                  </>

                )
                : 'Nouvelle étude de faisabilité'
            }</Title>
          </div>
        </Row>
        <div className='flex items-center space-x-2'>
          {feasabilityId && !unsaved
            ? (
              <>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => setModal(true)}
                >
                  Dupliquer
                </Button>
                <Button
                  type="primary"
                  onClick={() => getPDF()}
                  loading={loadingPdf}
                  icon={<FilePdfOutlined />}
                >
                  PDF
                </Button>
              </>
            )
            : (
              <Tag
                icon={<ExclamationCircleOutlined />}
                color="red"
              >
                Étude non sauvegardée
              </Tag>
            )
          }
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => (feasabilityId ? handleCreate() : setModal(true))}
            icon={<SaveOutlined />}
          >
            Sauvegarder
          </Button>
        </div>
      </div>
      <Content>
        <Spin spinning={loading}>
          <Card className='mt-2 relative'>
            <div
              className='flex justify-between items-center cursor-pointer bg-white select-none sticky top-0 py-2 z-40'
            >
              <div className='flex items-center space-x-2'>
                <div className='flex items-center space-x-2'>
                  <InfoCircleOutlined className='text-lg' />
                  <p className='font-semibold text-xl mb-0'>La recherche</p>
                </div>
                {visibleStudy && (
                  <div className='flex items-center space-x-2'>
                    <div className='flex space-x-2 items-center'>
                      <div
                        onClick={() => setActiveKey('A')}
                        className='bg-blue-50 border border-blue-600 p-3 text-blue-600 rounded-lg flex flex-col gap-1'
                      >
                        <span>A : {datas[0]?.totalProfils > 706325 ? '706 325' : numberStr(datas[0]?.totalProfils) || 0}</span>
                        {previousA &&
                          ((((datas[0]?.totalProfils > 706325 ? 706325 : datas[0]?.totalProfils || 0) / previousA) - 1) * 100) !== 0 &&
                          (
                            <span className={classNames(
                              'text-xs',
                              Math.sign((((datas[0].totalProfils > 706325 ? 706325 : datas[0]?.totalProfils || 0) / previousA) - 1) * 100) === -1
                                ? 'text-red-500'
                                : 'text-emerald-500',
                            )}>
                              {`${((((datas[0]?.totalProfils > 706325 ? 706325 : datas[0]?.totalProfils || 0) / previousA) - 1) * 100).toFixed()}% de candidats`}
                            </span>
                          )}
                      </div>
                      <Tooltip title='Dupliquer A vers B'>
                        <Button onClick={(e) => { e.stopPropagation(); handleDuplicate() }} size='small'>
                          <CopyOutlined />
                          <CaretRightOutlined />
                        </Button>
                      </Tooltip>
                      <div
                        onClick={() => setActiveKey('B')}
                        className='bg-purple-50 border border-purple-600 p-3 text-purple-600 rounded-lg flex flex-col gap-1'
                      >
                        <span>B : {datas[1]?.totalProfils > 706325 ? '706 325' : numberStr(datas[1]?.totalProfils) || 0}</span>
                        {datas[1]?.totalProfils !== 0 && previousB &&
                          (
                            <span className={classNames(
                              'text-xs',
                              Math.sign((((datas[1].totalProfils > 706325 ? 706325 : datas[1]?.totalProfils || 0) / previousB) - 1) * 100) === -1
                                ? 'text-red-500'
                                : 'text-emerald-500',
                            )}>
                              {`${((((datas[1]?.totalProfils > 706325 ? 706325 : datas[1]?.totalProfils || 0) / previousB) - 1) * 100).toFixed()}% de candidats`}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {visibleStudy
                ? (
                  <div className='space-x-2'>
                    <Button type='link' danger onClick={() => setDatas([{}, {}])}>Réinitialiser les 2 recherches</Button>
                    <UpOutlined onClick={() => setVisibleStudy(!visibleStudy)} className='text-xl' />
                  </div>
                )
                : <DownOutlined onClick={() => setVisibleStudy(!visibleStudy)} className='text-xl' />
              }
            </div>
            {visibleStudy && (
              <Tabs
                activeKey={activeKey}
                onTabClick={(e) => setActiveKey(e)}
                items={[
                  {
                    label: <span><SearchOutlined />Recherche A</span>,
                    key: 'A',
                    children: (
                      <NewForm
                        unsaved={() => setUnsaved(true)}
                        initialValues={datas[0] || {}}
                        combine={(values) => setDatas([values, datas[1]])}
                        lists={lists}
                        feasabilityId={feasabilityId}
                        isB={false}
                      />),
                  }, {
                    label: <span><SearchOutlined />Recherche B</span>,
                    key: 'B',
                    children: (
                      <>
                        <NewForm
                          unsaved={() => setUnsaved(true)}
                          initialValues={datas[1] || {}}
                          combine={(values) => setDatas([datas[0], values])}
                          lists={lists}
                          feasabilityId={feasabilityId}
                          isB={true}
                          setDatas={setDatas}
                          datas={datas}
                          isNotPDF={isNotPDF}
                          setIsNotPDF={setIsNotPDF}
                        />
                      </>
                    ),
                  },
                ]}
              />
            )}
          </Card>
          <Details update={setDetails} values={details} loading={loading} unsaved={() => setUnsaved(true)}/>
          <Modal
            title={feasabilityId ? 'Dupliquer l\'étude de faisabilité' : 'Sauvegarder l\'étude de faisabilité'}
            open={!!(modal && datas.length > 1)}
            onOk={feasabilityId ? handleDuplicateEDF : handleCreate}
            onCancel={() => setModal(false)}
          >
            <p className="my-2 font-semibold">Société</p>
            <CompaniesAutocomplete
              className='w-full'
              onSelect={(company) => {
                setStudyInfos({ ...studyInfos, _company: company._id })
              }}
            />
            <Checkbox className='mb-4' onChange={(e) => setHubspotVisible(e.target.checked)}>
              Je ne trouve pas mon entreprise
            </Checkbox>
            {hubspotVisible &&
              <ImportHubspotCompany setNewCompany={setNewCompany} />
            }
            <p className="mb-2 mt-4 font-semibold">Titre de l&apos;étude</p>
            <Input onChange={(e) => setStudyInfos({ ...studyInfos, title: e.target.value })} />
          </Modal>
        </Spin>
      </Content>
    </Layout >
  )
}

export default NewStudyContainer
